import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../speciality/Speciality.css";

import redSheildImage from "../../assets/images/layouts/red-sheild.jpg";

function About() {
  const [quantity, setQuantity] = useState(1);
  const { t, i18n } = useTranslation();
  const isRTL = i18n.language === 'ar';

  return (
    <section className={`about ${isRTL ? 'el-messiri' : ''}`} style={{ direction: isRTL ? 'rtl' : 'ltr' }}>
      <div className="shape"></div>
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 pt-xl-5 pt-sm-0">
            <div style={{ width: "100%", height: "100%" }}>
              <img
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "30px",
                  boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                }}
                src={redSheildImage}
              />
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 pt-5">
            <div style={{ width: "100%" }}>
              <h3 className={`heading ${isRTL ? 'el-messiri' : ''}`}>{t("Red Shield")}</h3>
              <hr />
              <div className="mt-17">
                <h4 className={`heading ${isRTL ? '' : ''}`}>
                  <span
                    style={{
                      background: "linear-gradient(264.28deg, #0291f7 -38.2%, #0058c6 103.12%)",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                    }}
                  >
                  SAR 39.99
                  </span>
                  <span
                    style={{
                      textDecorationLine: "line-through",
                      textDecorationThickness: "1px",
                      color: "#999",
                      fontSize: "25px",
                      fontWeight: "normal",
                      marginLeft: "10px",
                    }}
                  >
                    SAR 75
                  </span>
                </h4>
                <p className={`mt-2 fs-17 ${isRTL ? 'el-messiri' : ''}`}>{t("In stock")}</p>
                <p className={`mt-4 fs-17 ${isRTL ? 'el-messiri' : ''}`}>
                  {t("At Limitless Health and Wellness, we're committed to excellence. Red Shield reflects our dedication with rigorous testing and the finest ingredients, ensuring top quality for your health. Trust Red Shield for exceptional benefits.")}
                </p>
                <hr />
                <div className="mt-4 d-flex gap-5">
                  <div className="quantity-control">
                    <button
                      className="quantity-btn"
                      onClick={() => {
                        quantity > 1 && setQuantity(quantity - 1);
                      }}
                    >
                      <svg
                        style={{ color: "black" }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-dash-lg"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8Z"
                        />
                      </svg>
                    </button>
                    <input
                      type="number"
                      className="quantity-input"
                      value={quantity}
                      onChange={(e) => setQuantity(e.target.value)}
                      step="1"
                      min="1"
                      max=""
                      name="quantity"
                      onKeyDown={(e) => {
                        if (e.key === "-" || e.key === "+") {
                          e.preventDefault();
                        }
                      }}
                    />
                    <button
                      onClick={() => setQuantity(quantity + 1)}
                      className="quantity-btn"
                    >
                      <svg
                        style={{ color: "black" }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-plus-lg"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
                        />
                      </svg>
                    </button>
                  </div>
                  <Link
                    onClick={() =>
                      window.open(
                        `https://wa.me/966553651041?text=${t("Interested in Red Shield")}`
                      )
                    }
                    className="new-sc-button"
                    style={{ color: "white" }}
                  >
                                    <p className={` ${isRTL ? 'el-messiri' : ''}`}>

                    <span>{t("Buy Now")}</span></p>
                  </Link>
                </div>
              </div>
              <div className="mt-25">
                <ul style={{ padding: "0px 15px" }}>
                  <li style={{ listStyleType: "disc" }} className={`fs-17 ${isRTL ? 'el-messiri' : ''}`}>
                    {t("Enhanced Blood Flow & Memory")}
                  </li>
                  <li style={{ listStyleType: "disc" }} className={`fs-17 ${isRTL ? 'el-messiri' : ''}`}>
                    {t("Immunity Improvement")}
                  </li>
                  <li style={{ listStyleType: "disc" }} className={`fs-17 ${isRTL ? 'el-messiri' : ''}`}>
                    {t("Fatigue Relief")}
                  </li>
                  <li style={{ listStyleType: "disc" }} className={`fs-17 ${isRTL ? 'el-messiri' : ''}`}>
                    {t("Anti-oxidation")}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
