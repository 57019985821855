import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import logo from "../../assets/images/logo/logo.png";
import "./styles.scss";

const Header = () => {
  const { i18n } = useTranslation();
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScroll(window.scrollY > 300);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleTranslateClick = () => {
    i18n.changeLanguage(i18n.language === "en" ? "ar" : "en");
  };

  return (
    <header className={`header ${scroll ? "is-fixed" : ""}`}>
      <div className="container big">
        <div className="row">
          <div className="col-12">
            <div className="header__body">
              <div className="header__logo">
                <Link to="/">
                  <img id="site-logo" src={logo} alt="limitless" />
                </Link>
              </div>
              <div className="header__translate-button">
                <Link 
                  onClick={handleTranslateClick}
                  className="new-sc-button"
                  style={{ color: "white" }}
                >
                  {i18n.language === "en" ? "عربي" : "English"}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
