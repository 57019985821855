import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo/logo.png";
import { useTranslation } from "react-i18next";
import i18n from 'i18next'; // Import i18n

function Footer() {
  const [isVisible, setIsVisible] = useState(false);
  const { t } = useTranslation();
  const isRTL = i18n.language === 'ar';

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 500) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };
    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  return (
    <footer className={`footer ${isRTL ? 'el-messiri' : ''}`}>
      <div className="shape"></div>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="footer__bottom">
              <Link to="/" className="logo">
                <img src={logo} alt="LimitLess" />
              </Link>
              <div className='center mb--30'>
                <p>{t("Copyright © 2023, LimitLess. Designed by MVP")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isVisible && (
        <Link to="#" onClick={scrollToTop} id="scroll-top">
          <span className="icon-arrow-top"></span>
        </Link>
      )}
    </footer>
  );
}

export default Footer;
