import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import "../speciality/Speciality.css";

function Speciality(props) {
  const { t, i18n } = useTranslation();
  const { data } = props;
  const isRTL = i18n.language === 'ar';

  const dataBlock = {
    subheading: t("Our Speciality"),
    heading: t("Complete Solutions for your Wellness"),
    desc: t("limitless is the premier marketplace for nifties, which are digital items you can truly own for yourself"),
  };

  return (
    <section className={`speciality ${isRTL ? 'el-messiri' : ''}`} style={{ direction: isRTL ? 'rtl' : 'ltr' }}>
      <div className="shape right"></div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="block-text center">
              <h6 className={`sub-heading ${isRTL ? 'el-messiri' : ''}`}>
                <span>{dataBlock.subheading}</span>
              </h6>
              <h3 className={`heading pd ${isRTL ? 'el-messiri' : ''}`}>{dataBlock.heading}</h3>
              <p className={isRTL ? 'el-messiri' : ''}>{dataBlock.desc}</p>
            </div>
          </div>
          {data.map((idx) => (
            <Fragment key={idx.id}>
              {idx?.id === 4 && <div className="col-xl-2 col-md-6 "></div>}
              <div key={idx.id} className="col-xl-4 col-md-6 ">
                <div className={`speciality-box ${isRTL ? 'el-messiri' : ''}`}>
                  <div className="icon">
                    <img src={idx.img} alt="LimitLess" />
                  </div>
                  <h5 className={`title ${isRTL ? 'el-messiri' : ''}`}>{t(idx.title)}</h5>
                  <p className={isRTL ? 'el-messiri' : ''}>{t(idx.desc)}</p>
                  <h3 className={`number ${isRTL ? 'el-messiri' : ''}`}>0{idx.id}</h3>
                </div>
              </div>
            </Fragment>
          ))}
          <div className="col-xl-2 col-md-6 "></div>
        </div>
      </div>
    </section>
  );
}

export default Speciality;
