import React from "react";
import img1 from "../../assets/images/layouts/banner.png";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import "../speciality/Speciality.css";

function Banner() {
  const isRTL = i18n.language === "ar";
  const { t } = useTranslation();

  return (
    <section
      className={`banner ${isRTL ? "el-messiri" : ""}`}
      style={{ direction: isRTL ? "rtl" : "ltr" }}
    >
      <div className="shape right"></div>
      <div className="container big">
        <div className="row">
          <div className={`${isRTL? "col-xl-6 col-lg-6 col-md-12 ar-responsive-custom-container" : "col-xl-6 col-lg-6 col-md-12 responsive-custom-container"}`}>
            <div className="banner__left">
              <div className="block-text">
                <h2
                  className={`heading ${
                    isRTL ? "el-messiri arabic-heading " : ""
                  }`}
                >
                  <div className={` ${isRTL ? "shift-left" : ""}`}>
                    {t("Your Journey")} <br />
                  </div>
                  {t("to")}{" "}
                  <span
                    className={`mb-5 s1 arlo_tm_animation_text_word`}
                    style={{
                      background:
                        "linear-gradient(264.28deg, #0087e8 -38.2%, #0087e8 103.12%)",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                    }}
                  >
                    {t("Wellness")}
                  </span>{" "}
                  <br />
                  <div className={` ${isRTL ? "shift-left2" : ""}`}>
                    {t("Begins Here")}
                  </div>
                </h2>
                <p className={`desc ${isRTL ? "el-messiri ar-desc" : ""}`}>
                  {t(
                    "Experience limitless well-being through our innovative Oral Dissolving Films (ODF) solutions, designed for ease and effectiveness."
                  )}
                </p>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-12">
            <div className="banner__right">
              <div className="image">
                <img src={img1} alt="LimitLess" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Banner;
