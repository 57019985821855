import React from "react";
import dataBox from "../assets/fake-data/data-box";
import About from "../components/about/About";
import Banner from "../components/banner/Banner";
import Footer from "../components/footer/Footer";
import Speciality from "../components/speciality/Speciality";
function Home01() {
  return (
    <div className="home-1 wrapper">
      <Banner />
      <About />
      <Speciality data={dataBox} />
      <Footer />    
    </div>
  );
}
export default Home01;
